/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from 'react'
import { listProductCountries } from '../graphql/queries'
import EsmeGram from './EsmeGram'
import { getOverrideProps } from '@aws-amplify/ui-react/internal'
import {
  Collection,
  Pagination,
  Text,
  RadioGroupField,
  Radio,
  Loader,
  useTheme,
  Flex
} from '@aws-amplify/ui-react'
import { API } from 'aws-amplify'
const nextToken = {}
const apiCache = {}
import stringSimilarity from 'string-similarity'

export default function ProductsCountryCollection(props) {
  const { tokens } = useTheme()
  const { items: itemsProp, overrideItems, overrides, ...rest } = props
  const [pageIndex, setPageIndex] = React.useState(1)
  const [hasMorePages, setHasMorePages] = React.useState(true)
  const [items, setItems] = React.useState([])
  const [isApiPagination, setIsApiPagination] = React.useState(false)
  const [instanceKey, setInstanceKey] = React.useState('newGuid')
  const [loading, setLoading] = React.useState(true)
  const [maxViewed, setMaxViewed] = React.useState(1)
  const [selectedCategory, setCategory] = React.useState('')

  const pageSize = 100
  const isPaginated = false
  React.useEffect(() => {
    nextToken[instanceKey] = ''
    apiCache[instanceKey] = []
  }, [instanceKey])
  React.useEffect(() => {
    setIsApiPagination(!!!itemsProp)
  }, [itemsProp])
  const handlePreviousPage = () => {
    setPageIndex(pageIndex - 1)
  }
  const handleNextPage = () => {
    setPageIndex(pageIndex + 1)
  }
  const jumpToPage = pageNum => {
    setPageIndex(pageNum)
  }
  const loadPage = async (page, category) => {
    const cacheUntil = page * pageSize + 1
    const newCache = apiCache[instanceKey].slice()
    let newNext = nextToken[instanceKey]
    while ((newCache.length < cacheUntil || !isPaginated) && newNext != null) {
      setLoading(true)
      const variables = !category
        ? {}
        : {
            filter: {
              productCountryCategoryId: {
                eq: category ?? selectedCategory
              }
            },
            limit: pageSize
          }
      if (newNext) {
        variables['nextToken'] = newNext
      }
      const result = (
        await API.graphql({
          query: listProductCountries,
          variables
        })
      ).data.listProductCountries
      result.items = result.items.filter(item => item.Products.status)
      newCache.push(...result.items)
      newNext = result.nextToken
    }
    const cacheSlice = isPaginated
      ? newCache.slice((page - 1) * pageSize, page * pageSize)
      : newCache
    setItems(cacheSlice)
    setHasMorePages(!!newNext)
    setLoading(false)
    apiCache[instanceKey] = newCache
    nextToken[instanceKey] = newNext
  }
  React.useEffect(() => {
    loadPage(pageIndex)
  }, [pageIndex])
  React.useEffect(() => {
    setMaxViewed(Math.max(maxViewed, pageIndex))
  }, [pageIndex, maxViewed, setMaxViewed])
  return (
    <div style={{ backgroundColor: '#EFF0F0', padding: '24px' }}>
      <Text
        fontFamily='Inter'
        fontSize='32px'
        fontWeight='600'
        color='rgba(13,26,38,1)'
        lineHeight='32px'
        textAlign='center'
        display='block'
        direction='column'
        justifyContent='unset'
        width='unset'
        height='unset'
        gap='unset'
        alignItems='unset'
        shrink='0'
        position='relative'
        padding='0px 0px 0px 0px'
        whiteSpace='pre-wrap'
        children='PRODUCTOS'
      ></Text>
      <br></br>
      <Flex>
        <RadioGroupField
          name='line'
          direction='columnd'
          defaultValue='omnilife'
          text-align='center'
          margin='0 auto'
          value={selectedCategory}
          onChange={async e => {
            //
            nextToken[instanceKey] = ''
            apiCache[instanceKey] = []
            setCategory(e.target.value)
            loadPage(1, e.target.value)
          }}
        >
          <Radio value='0687c4ac-9b64-41e9-95fc-81e3ecb7bcc4'>
            CUIDADO PERSONAL
          </Radio>
          <Radio value='5be49a11-879b-46b7-bf08-1623d483db53'>NUTRICION</Radio>
        </RadioGroupField>
      </Flex>
      <br></br>
      {() => {
        if (loading) {
          return (
            <Loader margin='0 auto' size='large'>
              <br></br>
              <br></br>
              <br></br>
            </Loader>
          )
        }
      }}
      <Collection
        type='grid'
        isSearchable
        searchFilter={(item, keyword) => {
          if (item?.name && keyword) {
            keyword = keyword
              .normalize('NFD')
              .replace(/[\u0300-\u036f]/g, '')
              .trim()

            if (item.name.toLowerCase().startsWith(keyword.toLowerCase())) {
              return true
            }
            const words = item.name.split(' ')
            for (let word of words) {
              if (
                stringSimilarity.compareTwoStrings(
                  word.toLowerCase(),
                  keyword.toLowerCase()
                ) > 0.4
              ) {
                return true
              }
            }
          }
        }}
        searchPlaceholder='Buscar...'
        templateColumns={{
          base: '1fr',
          small: '1fr',
          medium: '1fr 1fr',
          large: '1fr 1fr 1fr'
        }}
        autoFlow='row'
        alignItems='stretch'
        justifyContent='stretch'
        backgroundColor={tokens.colors.background.disabled}
        searchNoResultsFound={
          <Flex justifyContent='center'>
            <Text color='purple.80' fontSize='1rem'>
              Sin resultados, por favor revisa
            </Text>
          </Flex>
        }
        padding='12px !important'
        itemsPerPage={pageSize}
        isPaginated={!isApiPagination && isPaginated}
        items={itemsProp || (loading ? new Array(pageSize).fill({}) : items)}
        {...getOverrideProps(overrides, 'PackagesCollection')}
        {...rest}
      >
        {(item, index) => {
          if (loading) {
            return (
              <Loader margin='0 auto' size='large'>
                <br></br>
                <br></br>
                <br></br>
              </Loader>
            )
          }
          return (
            <EsmeGram
              packages={item}
              category={item.packagesCategoryId}
              showCatalog={item.Category.id
                .toString()
                .includes('0687c4ac-9b64-41e9-95fc-81e3ecb7bcc4')}
              price={item}
              brand={item.packagesCategoryId}
              key={item.id}
              {...(overrideItems && overrideItems({ item, index }))}
            ></EsmeGram>
          )
        }}
      </Collection>
      {isApiPagination && isPaginated && (
        <Pagination
          currentPage={pageIndex}
          totalPages={maxViewed}
          hasMorePages={hasMorePages}
          onNext={handleNextPage}
          onPrevious={handlePreviousPage}
          onChange={jumpToPage}
        />
      )}
    </div>
  )
}
