/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_appsync_graphqlEndpoint": "https://hcvlr5wjwzahfmvroiaogj6yke.appsync-api.us-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-2",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-mhb44g66ujdzxmpry76ysoru7e",
    "aws_content_delivery_bucket": "esmegram-20231119184148-hostingbucket-dev",
    "aws_content_delivery_bucket_region": "us-west-2",
    "aws_content_delivery_url": "https://d1tfcuxblq6p62.cloudfront.net"
};


export default awsmobile;
