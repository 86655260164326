/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from 'react'
import { getOverrideProps } from './utils'
import { API } from 'aws-amplify'
import { createEmailsSubscriptions } from '../graphql/mutations'
import {
  Button,
  Divider,
  Flex,
  Text,
  TextField,
  Alert,
  PhoneNumberField
} from '@aws-amplify/ui-react'
export default function MarketingFooter(props) {
  const { overrides, ...rest } = props
  const [email, setEmail] = React.useState('')
  const [name, setName] = React.useState('')
  const [phone, setPhone] = React.useState('')
  const [showMessage, setShowMessage] = React.useState(0)
  const [isDisabled, setIsDisable] = React.useState(false)

  return (
    <Flex
      gap='32px'
      direction='column'
      width='unset'
      height='unset'
      justifyContent='flex-start'
      alignItems='center'
      position='relative'
      padding='40px 40px 40px 40px'
      backgroundColor='rgba(250,250,250,1)'
      {...getOverrideProps(overrides, 'MarketingFooter')}
      {...rest}
    >
      <Flex
        gap='16px'
        direction='row'
        width='unset'
        height='unset'
        justifyContent='space-between'
        alignItems='flex-start'
        shrink='0'
        alignSelf='stretch'
        position='relative'
        padding='0px 0px 0px 0px'
        {...getOverrideProps(overrides, 'Frame 313')}
      >
        <Flex
          gap='10px'
          direction='column'
          width='unset'
          height='unset'
          justifyContent='flex-start'
          alignItems='flex-start'
          shrink='0'
          position='relative'
          padding='0px 0px 0px 0px'
          {...getOverrideProps(overrides, 'Frame 434')}
        >
          <Text
            fontFamily='Inter'
            fontSize='20px'
            fontWeight='600'
            color='rgba(48,64,80,1)'
            lineHeight='30px'
            textAlign='left'
            display='block'
            direction='column'
            justifyContent='unset'
            width='unset'
            height='unset'
            gap='unset'
            alignItems='unset'
            shrink='0'
            position='relative'
            padding='0px 0px 0px 0px'
            whiteSpace='pre-wrap'
            children='Suscríbete a nuestras novedades'
            {...getOverrideProps(overrides, 'Suscríbete a nuestras novedades')}
          ></Text>
          <Text
            fontFamily='Inter'
            fontSize='16px'
            fontWeight='400'
            color='rgba(48,64,80,1)'
            lineHeight='24px'
            textAlign='left'
            display='block'
            direction='column'
            justifyContent='unset'
            width='unset'
            height='unset'
            gap='unset'
            alignItems='unset'
            shrink='0'
            position='relative'
            padding='0px 0px 0px 0px'
            whiteSpace='pre-wrap'
            children='Sin spam. Lo prometemos.'
            {...getOverrideProps(overrides, 'Sin spam. Lo prometemos.')}
          ></Text>
          <TextField
            width='unset'
            height='unset'
            placeholder='Correo electrónico'
            shrink='0'
            size='small'
            type='email'
            isDisabled={false}
            labelHidden={true}
            onChange={e => setEmail(e.target.value)}
            variation='default'
            {...getOverrideProps(overrides, 'TextField')}
          ></TextField>
          <TextField
            width='unset'
            height='unset'
            placeholder='Tu nombre'
            shrink='0'
            size='small'
            isDisabled={false}
            labelHidden={true}
            onChange={e => setName(e.target.value)}
            variation='default'
            {...getOverrideProps(overrides, 'TextField')}
          ></TextField>
          {/* <TextField
            width='unset'
            height='unset'
            placeholder='Tu teléfono'
            shrink='0'
            size='default'
            type='number'
            isDisabled={false}
            labelHidden={true}
            onChange={e => setPhone(e.target.value)}
            variation='default'
            {...getOverrideProps(overrides, 'TextField')}
          ></TextField> */}
          <PhoneNumberField
            defaultDialCode='+506'
            label='Teléfono'
            size='small'
            placeholder='88159414'
            onChange={e => setPhone(e.target.value)}
          />
          <Button
            width='unset'
            height='unset'
            shrink='0'
            size='default'
            isDisabled={isDisabled}
            variation='default'
            children='Suscribirme'
            onClick={async e => {
              setIsDisable(true)
              if (
                /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/.test(
                  email
                ) &&
                name.length
              ) {
                await API.graphql({
                  query: createEmailsSubscriptions.replaceAll('__typename', ''),
                  variables: {
                    input: {
                      email: email,
                      name: name,
                      phone: phone
                    }
                  }
                })
                setShowMessage(1)
              } else {
                setShowMessage(2)
              }
              setIsDisable(false)
            }}
            {...getOverrideProps(overrides, 'Button')}
          ></Button>
          {!!showMessage && (
            <Alert
              variation={showMessage == 1 ? 'success' : 'warning'}
              isDismissible={true}
              onDismiss={() => setShowMessage(0)}
              hasIcon={true}
              heading={showMessage == 1 ? 'Información' : 'Atención'}
            >
              {showMessage == 1
                ? 'Suscripción exitosa'
                : 'El correo es inválido'}
            </Alert>
          )}
        </Flex>
      </Flex>
      <Divider
        width='unset'
        height='1px'
        shrink='0'
        alignSelf='stretch'
        size='small'
        orientation='horizontal'
        {...getOverrideProps(overrides, 'Divider')}
      ></Divider>
      <Flex
        gap='0'
        direction='row'
        width='unset'
        height='unset'
        justifyContent='space-between'
        alignItems='center'
        shrink='0'
        alignSelf='stretch'
        position='relative'
        padding='0px 0px 0px 0px'
        {...getOverrideProps(overrides, 'Frame 433')}
      >
        <Text
          fontFamily='Inter'
          fontSize='12px'
          fontWeight='400'
          color='rgba(102,112,133,1)'
          lineHeight='24px'
          textAlign='right'
          display='block'
          direction='column'
          justifyContent='unset'
          width='unset'
          height='unset'
          gap='unset'
          alignItems='unset'
          shrink='0'
          position='relative'
          padding='0px 0px 0px 0px'
          whiteSpace='pre-wrap'
          children='© 2023 Esme Alcocer. Todos los derechos reservados'
          {...getOverrideProps(
            overrides,
            '© 2023 Esme Alcocer. Todos los derechos reservados'
          )}
        ></Text>
      </Flex>
      <Flex
        gap='0'
        direction='row'
        width='unset'
        height='unset'
        justifyContent='space-between'
        alignItems='center'
        shrink='0'
        alignSelf='stretch'
        position='relative'
        padding='0px 0px 0px 0px'
        {...getOverrideProps(overrides, 'Frame 433')}
      >
        <Text
          fontFamily='Inter'
          fontSize='12px'
          fontWeight='400'
          color='rgba(102,112,133,1)'
          lineHeight='24px'
          textAlign='right'
          display='block'
          direction='column'
          justifyContent='unset'
          width='unset'
          height='unset'
          gap='unset'
          alignItems='unset'
          shrink='0'
          position='relative'
          padding='0px 0px 0px 0px'
          whiteSpace='pre-wrap'
          children='Sitio desarrollado por empresarios omnilife'
          {...getOverrideProps(
            overrides,
            'Sitio desarrollado por empresarios omnilife'
          )}
        ></Text>
      </Flex>
    </Flex>
  )
}
