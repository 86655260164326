/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from 'react'
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides
} from './utils'
import {
  Button,
  Flex,
  Text,
  useBreakpointValue,
  useTheme,
  PhoneNumberField,
  TextField
} from '@aws-amplify/ui-react'
import MyIcon from './MyIcon'

// import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal'

export default function MarketingPricing(props) {
  const [showModal1, setShowModal1] = React.useState(false)
  const [showModal2, setShowModal2] = React.useState(false)

  const handleClose1 = () => setShowModal1(false)
  const handleShow1 = () => setShowModal1(true)

  const handleClose2 = () => setShowModal2(false)
  const handleShow2 = () => setShowModal2(true)

  const [hasEmailError, setHasEmailError] = React.useState(true)
  const [dialCode, setDialCode] = React.useState('')

  const [phone, setPhone] = React.useState('')
  const [email, setEmail] = React.useState('')
  const { tokens } = useTheme()
  const { overrides: overridesProp, ...restProp } = props
  const variants = [
    {
      overrides: {
        'OMNILIFE/SEYT\u00DA': {},
        Unete: {},
        Text: {},
        'Frame 432': {},
        'Empresario/Consumidor': {},
        '\u20A125000': {},
        'Free forever, always39393258': {},
        'Frame 42939393278': {},
        MyIcon39393299: {},
        'Frame 43039393324': {},
        'Disponible en m\u00E1s de 20 paises29766691': {},
        'Frame 13829766688': {},
        MyIcon39393331: {},
        'Frame 43139393330': {},
        'Consume los productos de la marca Seyt\u00FA y Omnilife o genera un ingreso distribuyendolos':
          {},
        'Frame 13929766692': {},
        MyIcon39393349: {},
        'Frame 43039393348': {},
        'Compra en l\u00EDnea hasta un 40% de descuento': {},
        'Frame 13729766696': {},
        MyIcon39271734: {},
        'Frame 43039271733': {},
        'Recibe un kit de productos por afiliaci\u00F3n': {},
        'Frame 43039271732': {},
        Button29766686: {},
        'Frame 39029766683': {},
        'Frame 442': {},
        'Cliente VIP': {},
        GRATIS: {},
        'Free forever, always4055710': {},
        'Frame 4294055707': {},
        MyIcon4055713: {},
        'Frame 4304055712': {},
        'Disponible en m\u00E1s de 20 paises4055714': {},
        'Frame 1384055711': {},
        MyIcon4055717: {},
        'Frame 4314055716': {},
        'Compra en l\u00EDnea desde tu pa\u00EDs de residencia': {},
        'Frame 1394055715': {},
        MyIcon4055721: {},
        'Frame 4304055720': {},
        'Sin intermediarios': {},
        'Frame 1374055719': {},
        MyIcon4055725: {},
        'Frame 4304055724': {},
        'Envios hasta la puerta de tu casa': {},
        'Frame 4304055723': {},
        Button4055727: {},
        'Frame 3904055706': {},
        'Frame 444': {},
        'Frame 63': {},
        MarketingPricing: {}
      },
      variantValues: { breakpoint: 'medium' }
    },
    {
      overrides: {
        'OMNILIFE/SEYT\u00DA': {},
        Unete: {},
        Text: {},
        'Frame 432': {},
        'Empresario/Consumidor': {},
        '\u20A125000': {},
        'Free forever, always39393258': {},
        'Frame 42939393278': {},
        MyIcon39393299: {},
        'Frame 43039393324': {},
        'Disponible en m\u00E1s de 20 paises29766691': {
          width: '540px',
          shrink: '0'
        },
        'Frame 13829766688': {},
        MyIcon39393331: {},
        'Frame 43139393330': {},
        'Consume los productos de la marca Seyt\u00FA y Omnilife o genera un ingreso distribuyendolos':
          {},
        'Frame 13929766692': {},
        MyIcon39393349: {},
        'Frame 43039393348': {},
        'Compra en l\u00EDnea hasta un 40% de descuento': {},
        'Frame 13729766696': {},
        MyIcon39271734: {},
        'Frame 43039271733': {},
        'Recibe un kit de productos por afiliaci\u00F3n': {},
        'Frame 43039271732': {},
        Button29766686: {},
        'Frame 39029766683': { height: 'unset' },
        'Frame 442': { width: 'unset', alignSelf: 'stretch' },
        'Cliente VIP': {},
        GRATIS: {},
        'Free forever, always4055710': {},
        'Frame 4294055707': {},
        MyIcon4055713: {},
        'Frame 4304055712': {},
        'Disponible en m\u00E1s de 20 paises4055714': {
          width: '540px',
          shrink: '0'
        },
        'Frame 1384055711': {},
        MyIcon4055717: {},
        'Frame 4314055716': {},
        'Compra en l\u00EDnea desde tu pa\u00EDs de residencia': {},
        'Frame 1394055715': {},
        MyIcon4055721: {},
        'Frame 4304055720': {},
        'Sin intermediarios': {},
        'Frame 1374055719': {},
        MyIcon4055725: {},
        'Frame 4304055724': {},
        'Envios hasta la puerta de tu casa': {},
        'Frame 4304055723': {},
        Button4055727: {},
        'Frame 3904055706': { height: 'unset' },
        'Frame 444': { width: 'unset', alignSelf: 'stretch' },
        'Frame 63': { direction: 'column' },
        MarketingPricing: { width: '480px', height: 'unset' }
      },
      variantValues: { breakpoint: 'base' }
    }
  ]
  const breakpointHook = useBreakpointValue({
    base: 'base',
    medium: 'medium'
  })
  const rest = { style: { transition: 'all 0.25s' }, ...restProp }
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, {
      breakpoint: breakpointHook,
      ...props
    }),
    overridesProp || {}
  )
  return (
    <Flex
      gap='24px'
      direction='column'
      width='1440px'
      height='742px'
      justifyContent='flex-start'
      alignItems='center'
      overflow='hidden'
      position='relative'
      padding={{ base: '24px', large: '48px' }}
      // backgroundColor='rgba(250,250,250,1)'
      backgroundColor={tokens.colors.background.disabled}
      display='flex'
      {...getOverrideProps(overrides, 'MarketingPricing')}
      {...rest}
    >
      <Flex
        gap='12px'
        direction='column'
        width='unset'
        height='unset'
        justifyContent='flex-start'
        alignItems='center'
        shrink='0'
        position='relative'
        padding='0px 0px 0px 0px'
        display='flex'
        {...getOverrideProps(overrides, 'Frame 432')}
      >
        <Text
          fontFamily='Inter'
          fontSize='16px'
          fontWeight='600'
          color='rgba(149,4,149,1)'
          lineHeight='24px'
          textAlign='left'
          display='block'
          direction='column'
          justifyContent='unset'
          width='unset'
          height='unset'
          gap='unset'
          alignItems='unset'
          shrink='0'
          position='relative'
          padding='0px 0px 0px 0px'
          whiteSpace='pre-wrap'
          children='OMNILIFE/SEYTÚ'
          {...getOverrideProps(overrides, 'OMNILIFE/SEYT\u00DA')}
        ></Text>
        <Text
          fontFamily='Inter'
          fontSize='32px'
          fontWeight='600'
          color='rgba(13,26,38,1)'
          lineHeight='32px'
          textAlign='left'
          display='block'
          direction='column'
          justifyContent='unset'
          width='unset'
          height='unset'
          gap='unset'
          alignItems='unset'
          shrink='0'
          position='relative'
          padding='0px 0px 0px 0px'
          whiteSpace='pre-wrap'
          children='REGISTRATE'
          {...getOverrideProps(overrides, 'Unete')}
        ></Text>
        <Text
          fontFamily='Inter'
          fontSize='16px'
          fontWeight='400'
          color='rgba(92,102,112,1)'
          lineHeight='24px'
          textAlign='left'
          display='block'
          direction='column'
          justifyContent='unset'
          width='unset'
          height='unset'
          gap='unset'
          alignItems='unset'
          shrink='0'
          position='relative'
          padding='0px 0px 0px 0px'
          whiteSpace='pre-wrap'
          children=''
          {...getOverrideProps(overrides, 'Text')}
        ></Text>
      </Flex>
      <Flex
        gap='24px'
        direction='row'
        width='unset'
        height='unset'
        justifyContent='center'
        alignItems='center'
        overflow='hidden'
        shrink='0'
        alignSelf='stretch'
        position='relative'
        padding='0px 0px 0px 0px'
        display='flex'
        {...getOverrideProps(overrides, 'Frame 63')}
      >
        <Flex
          gap='10px'
          direction='row'
          width='660px'
          height='unset'
          justifyContent='center'
          alignItems='center'
          overflow='hidden'
          shrink='0'
          position='relative'
          padding='0px 0px 0px 0px'
          display='flex'
          {...getOverrideProps(overrides, 'Frame 442')}
        >
          <Flex
            gap='24px'
            direction='column'
            width='unset'
            height='518px'
            justifyContent='center'
            alignItems='center'
            overflow='hidden'
            grow='1'
            shrink='1'
            basis='0'
            position='relative'
            boxShadow='0px 2px 4px rgba(0.05098039284348488, 0.10196078568696976, 0.14901961386203766, 0.05000000074505806)'
            borderRadius='8px'
            padding='32px 32px 32px 32px'
            backgroundColor='rgba(255,255,255,1)'
            display='flex'
            {...getOverrideProps(overrides, 'Frame 39029766683')}
          >
            <Flex
              gap='8px'
              direction='column'
              width='unset'
              height='unset'
              justifyContent='flex-start'
              alignItems='flex-start'
              shrink='0'
              position='relative'
              padding='0px 0px 0px 0px'
              display='flex'
              {...getOverrideProps(overrides, 'Frame 42939393278')}
            >
              <Text
                fontFamily='Inter'
                fontSize='16px'
                fontWeight='600'
                color='rgba(149,4,149,1)'
                lineHeight='30px'
                textAlign='center'
                display='block'
                direction='column'
                justifyContent='unset'
                width='400px'
                height='unset'
                gap='unset'
                alignItems='unset'
                shrink='0'
                position='relative'
                padding='0px 0px 0px 0px'
                whiteSpace='pre-wrap'
                children='Empresario/Consumidor'
                {...getOverrideProps(overrides, 'Empresario/Consumidor')}
              ></Text>
              <Text
                fontFamily='Inter'
                fontSize='32px'
                fontWeight='600'
                color='rgba(13,26,38,1)'
                lineHeight='25px'
                textAlign='center'
                display='block'
                direction='column'
                justifyContent='unset'
                width='400px'
                height='unset'
                gap='unset'
                alignItems='unset'
                shrink='0'
                position='relative'
                padding='0px 0px 0px 0px'
                whiteSpace='pre-wrap'
                children='₡26000'
                {...getOverrideProps(overrides, '\u20A125000')}
              ></Text>
              <Text
                fontFamily='Inter'
                fontSize='16px'
                fontWeight='600'
                color='rgba(92,102,112,1)'
                lineHeight='25px'
                textAlign='center'
                display='none'
                direction='column'
                justifyContent='unset'
                width='400px'
                height='unset'
                gap='unset'
                alignItems='unset'
                shrink='0'
                position='relative'
                padding='0px 0px 0px 0px'
                whiteSpace='pre-wrap'
                children='Free forever, always'
                {...getOverrideProps(overrides, 'Free forever, always39393258')}
              ></Text>
            </Flex>
            <Flex
              gap='16px'
              direction='row'
              width='unset'
              height='unset'
              justifyContent='flex-start'
              alignItems='flex-start'
              shrink='0'
              alignSelf='stretch'
              position='relative'
              padding='0px 0px 0px 0px'
              display='flex'
              {...getOverrideProps(overrides, 'Frame 13829766688')}
            >
              <Flex
                gap='0'
                direction='row'
                width='unset'
                height='unset'
                justifyContent='flex-start'
                alignItems='flex-start'
                shrink='0'
                position='relative'
                borderRadius='8px'
                padding='8px 8px 8px 8px'
                backgroundColor='rgba(254,230,254,1)'
                display='flex'
                {...getOverrideProps(overrides, 'Frame 43039393324')}
              >
                <MyIcon
                  width='24px'
                  height='24px'
                  display='block'
                  gap='unset'
                  alignItems='unset'
                  justifyContent='unset'
                  overflow='hidden'
                  shrink='0'
                  position='relative'
                  padding='0px 0px 0px 0px'
                  type='checkmark'
                  {...getOverrideProps(overrides, 'MyIcon39393299')}
                ></MyIcon>
              </Flex>
              <Text
                fontFamily='Inter'
                fontSize='16px'
                fontWeight='400'
                color='rgba(13,26,38,1)'
                lineHeight='24px'
                textAlign='left'
                display='block'
                direction='column'
                justifyContent='unset'
                letterSpacing='0.01px'
                width='unset'
                height='unset'
                gap='unset'
                grow='1'
                shrink='1'
                basis='0'
                alignSelf='center'
                position='relative'
                padding='0px 0px 0px 0px'
                whiteSpace='pre-wrap'
                children='Disponible en más de 20 paises'
                {...getOverrideProps(
                  overrides,
                  'Disponible en m\u00E1s de 20 paises29766691'
                )}
              ></Text>
            </Flex>
            <Flex
              gap='16px'
              direction='row'
              width='unset'
              height='unset'
              justifyContent='center'
              alignItems='center'
              shrink='0'
              alignSelf='stretch'
              position='relative'
              padding='0px 0px 0px 0px'
              display='flex'
              {...getOverrideProps(overrides, 'Frame 13929766692')}
            >
              <Flex
                gap='0'
                direction='row'
                width='unset'
                height='unset'
                justifyContent='flex-start'
                alignItems='flex-start'
                shrink='0'
                position='relative'
                borderRadius='8px'
                padding='8px 8px 8px 8px'
                backgroundColor='rgba(254,230,254,1)'
                display='flex'
                {...getOverrideProps(overrides, 'Frame 43139393330')}
              >
                <MyIcon
                  width='24px'
                  height='24px'
                  display='block'
                  gap='unset'
                  alignItems='unset'
                  justifyContent='unset'
                  overflow='hidden'
                  shrink='0'
                  position='relative'
                  padding='0px 0px 0px 0px'
                  type='checkmark'
                  {...getOverrideProps(overrides, 'MyIcon39393331')}
                ></MyIcon>
              </Flex>
              <Text
                fontFamily='Inter'
                fontSize='16px'
                fontWeight='400'
                color='rgba(13,26,38,1)'
                lineHeight='24px'
                textAlign='left'
                display='block'
                direction='column'
                justifyContent='unset'
                letterSpacing='0.01px'
                width='unset'
                height='unset'
                gap='unset'
                alignItems='unset'
                grow='1'
                shrink='1'
                basis='0'
                alignSelf='center'
                position='relative'
                padding='0px 0px 0px 0px'
                whiteSpace='pre-wrap'
                children='Consume o genera ingresos y recibe asesoría personalizada'
                {...getOverrideProps(
                  overrides,
                  'Consume o genera ingresos y recibe asesoría personalizada'
                )}
              ></Text>
            </Flex>
            <Flex
              gap='16px'
              direction='row'
              width='unset'
              height='unset'
              justifyContent='flex-start'
              alignItems='flex-start'
              shrink='0'
              alignSelf='stretch'
              position='relative'
              padding='0px 0px 0px 0px'
              display='flex'
              {...getOverrideProps(overrides, 'Frame 13729766696')}
            >
              <Flex
                gap='0'
                direction='row'
                width='unset'
                height='unset'
                justifyContent='flex-start'
                alignItems='flex-start'
                shrink='0'
                position='relative'
                borderRadius='8px'
                padding='8px 8px 8px 8px'
                backgroundColor='rgba(254,230,254,1)'
                display='flex'
                {...getOverrideProps(overrides, 'Frame 43039393348')}
              >
                <MyIcon
                  width='24px'
                  height='24px'
                  display='block'
                  gap='unset'
                  alignItems='unset'
                  justifyContent='unset'
                  overflow='hidden'
                  shrink='0'
                  position='relative'
                  padding='0px 0px 0px 0px'
                  type='checkmark'
                  {...getOverrideProps(overrides, 'MyIcon39393349')}
                ></MyIcon>
              </Flex>
              <Text
                fontFamily='Inter'
                fontSize='16px'
                fontWeight='400'
                color='rgba(13,26,38,1)'
                lineHeight='24px'
                textAlign='left'
                display='block'
                direction='column'
                justifyContent='unset'
                letterSpacing='0.01px'
                width='unset'
                height='unset'
                gap='unset'
                alignItems='unset'
                grow='1'
                shrink='1'
                basis='0'
                alignSelf='center'
                position='relative'
                padding='0px 0px 0px 0px'
                whiteSpace='pre-wrap'
                children='Compra en línea o tienda fisica hasta un 40% de descuento'
                {...getOverrideProps(
                  overrides,
                  'Compra en l\u00EDnea o tienda fisica hasta un 40% de descuento'
                )}
              ></Text>
            </Flex>
            <Flex
              gap='16px'
              direction='row'
              width='unset'
              height='unset'
              justifyContent='flex-start'
              alignItems='flex-start'
              shrink='0'
              alignSelf='stretch'
              position='relative'
              padding='0px 0px 0px 0px'
              display='flex'
              {...getOverrideProps(overrides, 'Frame 43039271732')}
            >
              <Flex
                gap='0'
                direction='row'
                width='unset'
                height='unset'
                justifyContent='flex-start'
                alignItems='flex-start'
                shrink='0'
                position='relative'
                borderRadius='8px'
                padding='8px 8px 8px 8px'
                backgroundColor='rgba(254,230,254,1)'
                display='flex'
                {...getOverrideProps(overrides, 'Frame 43039271733')}
              >
                <MyIcon
                  width='24px'
                  height='24px'
                  display='block'
                  gap='unset'
                  alignItems='unset'
                  justifyContent='unset'
                  overflow='hidden'
                  shrink='0'
                  position='relative'
                  padding='0px 0px 0px 0px'
                  type='checkmark'
                  {...getOverrideProps(overrides, 'MyIcon39271734')}
                ></MyIcon>
              </Flex>
              <Text
                fontFamily='Inter'
                fontSize='16px'
                fontWeight='400'
                color='rgba(13,26,38,1)'
                lineHeight='24px'
                textAlign='left'
                display='block'
                direction='column'
                justifyContent='unset'
                letterSpacing='0.01px'
                width='unset'
                height='unset'
                gap='unset'
                alignItems='unset'
                grow='1'
                shrink='1'
                basis='0'
                alignSelf='center'
                position='relative'
                padding='0px 0px 0px 0px'
                whiteSpace='pre-wrap'
                children='Recibe un kit de productos al registrarte'
                {...getOverrideProps(
                  overrides,
                  'Recibe un kit de productos al registrarte'
                )}
              ></Text>
            </Flex>
            {/* <a
              href='https://wa.me/50688159414?text=Hola, me gustaría ser parte de tu red de distribuidores'
              target='_blank'
              rel='noopener noreferrer'
              width='unset'
              height='unset'
              shrink='0'
              alignSelf='stretch'
              size='large'
              isDisabled={false}
              variation='primary'
              children='Me interesa'
              {...getOverrideProps(overrides, 'Button29766686')}
            > */}
            <>
              <Button
                width='unset'
                height='unset'
                shrink='0'
                alignSelf='stretch'
                size='large'
                isDisabled={false}
                variation='primary'
                children='Me interesa'
                onClick={handleShow1}
                {...getOverrideProps(overrides, 'Button29766686')}
              ></Button>
              {/* </a> */}
              {/* <Button onClick={handleShow}>Launch demo modal</Button> */}
              <Modal show={showModal1} onHide={handleClose1} animation={false}>
                <Modal.Header closeButton>
                  <Modal.Title>COMPLETA TUS DATOS</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Form>
                    <Form.Group
                      className='mb-3'
                      controlId='exampleForm.ControlInput1'
                    >
                      <PhoneNumberField
                        defaultDialCode='+506'
                        label='Teléfono'
                        size='small'
                        placeholder='88159414'
                        dialCodeName='dial_code'
                        dialCodeLabel='Dial Code'
                        onDialCodeChange={e => setDialCode(e.target.value)}
                        onChange={e => setPhone(e.target.value)}
                      />
                    </Form.Group>
                    <Form.Group
                      className='mb-3'
                      controlId='exampleForm.ControlTextarea1'
                    >
                      <TextField
                        width='unset'
                        height='unset'
                        placeholder='Tu email'
                        shrink='0'
                        size='small'
                        type='email'
                        isDisabled={false}
                        labelHidden={true}
                        errorMessage='El correo es inválido'
                        hasError={hasEmailError}
                        onChange={e => {
                          setEmail(e.target.value)
                          if (
                            /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/.test(
                              email
                            )
                          ) {
                            setHasEmailError(false)
                          } else {
                            setHasEmailError(true)
                          }
                        }}
                        formNoValidate
                        variation='default'
                        {...getOverrideProps(overrides, 'TextField')}
                      ></TextField>
                    </Form.Group>
                  </Form>
                </Modal.Body>
                <Modal.Footer>
                  <a
                    href='https://portal.omnilife.com/registro?distributor_code=DCR0008131AOE&country_code=CR&lang=es'
                    target='_blank'
                    rel='noopener noreferrer'
                    width='unset'
                    height='unset'
                    shrink='0'
                    alignSelf='stretch'
                    size='large'
                    isDisabled={false}
                    onClick={() => {
                      if (
                        /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/.test(
                          email
                        ) &&
                        phone.length
                      ) {
                        const options = {
                          method: 'POST',
                          headers: {
                            'Content-Type': 'application/json',
                            'User-Agent': 'insomnia/2023.5.8'
                          },
                          body: `{"chat_id":-1002083737822,"text":"<strong>INTENTO DE REGISTRO</strong>
<strong>Teléfono:</strong> ${dialCode.length ? dialCode : '+506'}${phone}
<strong>Correo electrónico:</strong> ${email}","parse_mode":"HTML"}`
                        }

                        fetch(
                          'https://api.telegram.org/bot6458566181:AAFhpkpNKoV1RkBbAr_PipSAgUIMsYUieOg/sendMessage',
                          options
                        )
                          .then(response => response.json())
                          .then(response => console.log(response))
                          .catch(err => console.error(err))

                        setEmail('')
                        setHasEmailError(true)
                        setPhone('')
                      }
                    }}
                    variation='primary'
                    children='Me interesa'
                    {...getOverrideProps(overrides, 'Button29766686')}
                  >
                    <Button
                      variant='primary'
                      disabled={hasEmailError || !phone.length}
                      onClick={handleClose1}
                    >
                      Continuar
                    </Button>
                  </a>
                </Modal.Footer>
              </Modal>
            </>
          </Flex>
        </Flex>
        <Flex
          gap='10px'
          direction='row'
          width='660px'
          height='unset'
          justifyContent='center'
          alignItems='center'
          overflow='hidden'
          shrink='0'
          position='relative'
          padding='0px 0px 0px 0px'
          display='flex'
          {...getOverrideProps(overrides, 'Frame 444')}
        >
          <Flex
            gap='24px'
            direction='column'
            width='unset'
            height='518px'
            justifyContent='center'
            alignItems='center'
            overflow='hidden'
            grow='1'
            shrink='1'
            basis='0'
            position='relative'
            boxShadow='0px 2px 4px rgba(0.05098039284348488, 0.10196078568696976, 0.14901961386203766, 0.05000000074505806)'
            borderRadius='8px'
            padding='32px 32px 32px 32px'
            backgroundColor='rgba(255,255,255,1)'
            display='flex'
            {...getOverrideProps(overrides, 'Frame 3904055706')}
          >
            <Flex
              gap='8px'
              direction='column'
              width='unset'
              height='unset'
              justifyContent='flex-start'
              alignItems='flex-start'
              shrink='0'
              position='relative'
              padding='0px 0px 0px 0px'
              display='flex'
              {...getOverrideProps(overrides, 'Frame 4294055707')}
            >
              <Text
                fontFamily='Inter'
                fontSize='16px'
                fontWeight='600'
                color='rgba(149,4,149,1)'
                lineHeight='30px'
                textAlign='center'
                display='block'
                direction='column'
                justifyContent='unset'
                width='400px'
                height='unset'
                gap='unset'
                alignItems='unset'
                shrink='0'
                position='relative'
                padding='0px 0px 0px 0px'
                whiteSpace='pre-wrap'
                children='Cliente VIP'
                {...getOverrideProps(overrides, 'Cliente VIP')}
              ></Text>
              <Text
                fontFamily='Inter'
                fontSize='32px'
                fontWeight='600'
                color='rgba(13,26,38,1)'
                lineHeight='25px'
                textAlign='center'
                display='block'
                direction='column'
                justifyContent='unset'
                width='400px'
                height='unset'
                gap='unset'
                alignItems='unset'
                shrink='0'
                position='relative'
                padding='0px 0px 0px 0px'
                whiteSpace='pre-wrap'
                children='GRATIS'
                {...getOverrideProps(overrides, 'GRATIS')}
              ></Text>
              <Text
                fontFamily='Inter'
                fontSize='16px'
                fontWeight='600'
                color='rgba(92,102,112,1)'
                lineHeight='25px'
                textAlign='center'
                display='none'
                direction='column'
                justifyContent='unset'
                width='400px'
                height='unset'
                gap='unset'
                alignItems='unset'
                shrink='0'
                position='relative'
                padding='0px 0px 0px 0px'
                whiteSpace='pre-wrap'
                children='Free forever, always'
                {...getOverrideProps(overrides, 'Free forever, always4055710')}
              ></Text>
            </Flex>
            <Flex
              gap='16px'
              direction='row'
              width='unset'
              height='unset'
              justifyContent='flex-start'
              alignItems='flex-start'
              shrink='0'
              alignSelf='stretch'
              position='relative'
              padding='0px 0px 0px 0px'
              display='flex'
              {...getOverrideProps(overrides, 'Frame 1384055711')}
            >
              <Flex
                gap='0'
                direction='row'
                width='unset'
                height='unset'
                justifyContent='flex-start'
                alignItems='flex-start'
                shrink='0'
                position='relative'
                borderRadius='8px'
                padding='8px 8px 8px 8px'
                backgroundColor='rgba(254,230,254,1)'
                display='flex'
                {...getOverrideProps(overrides, 'Frame 4304055712')}
              >
                <MyIcon
                  width='24px'
                  height='24px'
                  display='block'
                  gap='unset'
                  alignItems='unset'
                  justifyContent='unset'
                  overflow='hidden'
                  shrink='0'
                  position='relative'
                  padding='0px 0px 0px 0px'
                  type='checkmark'
                  {...getOverrideProps(overrides, 'MyIcon4055713')}
                ></MyIcon>
              </Flex>
              <Text
                fontFamily='Inter'
                fontSize='16px'
                fontWeight='400'
                color='rgba(13,26,38,1)'
                lineHeight='24px'
                textAlign='left'
                display='block'
                direction='column'
                justifyContent='unset'
                letterSpacing='0.01px'
                width='unset'
                height='unset'
                gap='unset'
                alignItems='unset'
                grow='1'
                shrink='1'
                basis='0'
                alignSelf='center'
                position='relative'
                padding='0px 0px 0px 0px'
                whiteSpace='pre-wrap'
                children='Disponible en más de 20 paises'
                {...getOverrideProps(
                  overrides,
                  'Disponible en m\u00E1s de 20 paises4055714'
                )}
              ></Text>
            </Flex>
            <Flex
              gap='16px'
              direction='row'
              width='unset'
              height='unset'
              justifyContent='center'
              alignItems='center'
              shrink='0'
              alignSelf='stretch'
              position='relative'
              padding='0px 0px 0px 0px'
              display='flex'
              {...getOverrideProps(overrides, 'Frame 1394055715')}
            >
              <Flex
                gap='0'
                direction='row'
                width='unset'
                height='unset'
                justifyContent='flex-start'
                alignItems='flex-start'
                shrink='0'
                position='relative'
                borderRadius='8px'
                padding='8px 8px 8px 8px'
                backgroundColor='rgba(254,230,254,1)'
                display='flex'
                {...getOverrideProps(overrides, 'Frame 4314055716')}
              >
                <MyIcon
                  width='24px'
                  height='24px'
                  display='block'
                  gap='unset'
                  alignItems='unset'
                  justifyContent='unset'
                  overflow='hidden'
                  shrink='0'
                  position='relative'
                  padding='0px 0px 0px 0px'
                  type='checkmark'
                  {...getOverrideProps(overrides, 'MyIcon4055717')}
                ></MyIcon>
              </Flex>
              <Text
                fontFamily='Inter'
                fontSize='16px'
                fontWeight='400'
                color='rgba(13,26,38,1)'
                lineHeight='24px'
                textAlign='left'
                display='block'
                direction='column'
                justifyContent='unset'
                letterSpacing='0.01px'
                width='unset'
                height='unset'
                gap='unset'
                grow='1'
                shrink='1'
                basis='0'
                alignSelf='center'
                position='relative'
                padding='0px 0px 0px 0px'
                whiteSpace='pre-wrap'
                children='Compra en línea fácil y segura o en tienda fisica'
                {...getOverrideProps(
                  overrides,
                  'Compra en l\u00EDnea fácil y segura  o en tienda fisica'
                )}
              ></Text>
            </Flex>
            <Flex
              gap='16px'
              direction='row'
              width='unset'
              height='unset'
              justifyContent='flex-start'
              alignItems='flex-start'
              shrink='0'
              alignSelf='stretch'
              position='relative'
              padding='0px 0px 0px 0px'
              display='flex'
              {...getOverrideProps(overrides, 'Frame 1374055719')}
            >
              <Flex
                gap='0'
                direction='row'
                width='unset'
                height='unset'
                justifyContent='flex-start'
                alignItems='flex-start'
                shrink='0'
                position='relative'
                borderRadius='8px'
                padding='8px 8px 8px 8px'
                backgroundColor='rgba(254,230,254,1)'
                display='flex'
                {...getOverrideProps(overrides, 'Frame 4304055720')}
              >
                <MyIcon
                  width='24px'
                  height='24px'
                  display='block'
                  gap='unset'
                  alignItems='unset'
                  justifyContent='unset'
                  overflow='hidden'
                  shrink='0'
                  position='relative'
                  padding='0px 0px 0px 0px'
                  type='checkmark'
                  {...getOverrideProps(overrides, 'MyIcon4055721')}
                ></MyIcon>
              </Flex>
              <Text
                fontFamily='Inter'
                fontSize='16px'
                fontWeight='400'
                color='rgba(13,26,38,1)'
                lineHeight='24px'
                textAlign='left'
                display='block'
                direction='column'
                justifyContent='unset'
                letterSpacing='0.01px'
                width='unset'
                height='unset'
                gap='unset'
                alignItems='unset'
                grow='1'
                shrink='1'
                basis='0'
                alignSelf='center'
                position='relative'
                padding='0px 0px 0px 0px'
                whiteSpace='pre-wrap'
                children='Asesoría personalizada'
                {...getOverrideProps(overrides, 'Asesoría personalizada')}
              ></Text>
            </Flex>
            <Flex
              gap='16px'
              direction='row'
              width='unset'
              height='unset'
              justifyContent='flex-start'
              alignItems='flex-start'
              shrink='0'
              alignSelf='stretch'
              position='relative'
              padding='0px 0px 0px 0px'
              display='flex'
              {...getOverrideProps(overrides, 'Frame 4304055723')}
            >
              <Flex
                gap='0'
                direction='row'
                width='unset'
                height='unset'
                justifyContent='flex-start'
                alignItems='flex-start'
                shrink='0'
                position='relative'
                borderRadius='8px'
                padding='8px 8px 8px 8px'
                backgroundColor='rgba(254,230,254,1)'
                display='flex'
                {...getOverrideProps(overrides, 'Frame 4304055724')}
              >
                <MyIcon
                  width='24px'
                  height='24px'
                  display='block'
                  gap='unset'
                  alignItems='unset'
                  justifyContent='unset'
                  overflow='hidden'
                  shrink='0'
                  position='relative'
                  padding='0px 0px 0px 0px'
                  type='checkmark'
                  {...getOverrideProps(overrides, 'MyIcon4055725')}
                ></MyIcon>
              </Flex>
              <Text
                fontFamily='Inter'
                fontSize='16px'
                fontWeight='400'
                color='rgba(13,26,38,1)'
                lineHeight='24px'
                textAlign='left'
                display='block'
                direction='column'
                justifyContent='unset'
                letterSpacing='0.01px'
                width='unset'
                height='unset'
                gap='unset'
                alignItems='unset'
                grow='1'
                shrink='1'
                basis='0'
                alignSelf='center'
                position='relative'
                padding='0px 0px 0px 0px'
                whiteSpace='pre-wrap'
                children='Envíos a la dirección de tu preferencia'
                {...getOverrideProps(
                  overrides,
                  'Envíos a la dirección de tu preferencia'
                )}
              ></Text>
            </Flex>
            {/* <a
              href='https://wa.me/50688159414?text=Hola, me gustaría ser parte de tu red de distribuidores'
              target='_blank'
              rel='noopener noreferrer'
              width='unset'
              height='unset'
              shrink='0'
              alignSelf='stretch'
              size='large'
              isDisabled={false}
              variation='primary'
              children='Me interesa'
              {...getOverrideProps(overrides, 'Button29766686')}
            > */}
            <>
              <Button
                width='unset'
                height='unset'
                shrink='0'
                alignSelf='stretch'
                size='large'
                isDisabled={false}
                variation='primary'
                children='Me interesa'
                onClick={handleShow2}
                {...getOverrideProps(overrides, 'Button29766686')}
              ></Button>
              {/* </a> */}
              {/* <Button onClick={handleShow}>Launch demo modal</Button> */}
              <Modal show={showModal2} onHide={handleClose2} animation={false}>
                <Modal.Header closeButton>
                  <Modal.Title>COMPLETA TUS DATOS</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Form>
                    <Form.Group
                      className='mb-3'
                      controlId='exampleForm.ControlInput1'
                    >
                      <PhoneNumberField
                        defaultDialCode='+506'
                        label='Teléfono'
                        size='small'
                        placeholder='88159414'
                        dialCodeName='dial_code'
                        dialCodeLabel='Dial Code'
                        onDialCodeChange={e => setDialCode(e.target.value)}
                        onChange={e => setPhone(e.target.value)}
                      />
                    </Form.Group>
                    <Form.Group
                      className='mb-3'
                      controlId='exampleForm.ControlTextarea1'
                    >
                      <TextField
                        width='unset'
                        height='unset'
                        placeholder='Correo electrónico'
                        shrink='0'
                        size='small'
                        type='email'
                        isDisabled={false}
                        labelHidden={false}
                        errorMessage='El correo es inválido'
                        hasError={hasEmailError}
                        onChange={e => {
                          setEmail(e.target.value)
                          if (
                            /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/.test(
                              email
                            )
                          ) {
                            setHasEmailError(false)
                          } else {
                            setHasEmailError(true)
                          }
                        }}
                        variation='default'
                        {...getOverrideProps(overrides, 'TextField')}
                      ></TextField>
                    </Form.Group>
                  </Form>
                </Modal.Body>
                <Modal.Footer>
                  <a
                    href='https://portal.omnilife.com/registro-cliente?distributor_code=DCR0008131AOE&country_code=CR&lang=es'
                    target='_blank'
                    rel='noopener noreferrer'
                    width='unset'
                    height='unset'
                    shrink='0'
                    alignSelf='stretch'
                    size='large'
                    isDisabled={false}
                    onClick={() => {
                      if (
                        /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/.test(
                          email
                        ) &&
                        phone.length
                      ) {
                        const options = {
                          method: 'POST',
                          headers: {
                            'Content-Type': 'application/json',
                            'User-Agent': 'insomnia/2023.5.8'
                          },
                          body: `{"chat_id":-1002083737822,"text":"<strong>INTENTO DE REGISTRO CLIENTE VIP</strong>
<strong>Teléfono: </strong> ${dialCode.length ? dialCode : '+506'}${phone}
<strong>Correo electrónico:</strong> ${email}","parse_mode":"HTML"}`
                        }

                        fetch(
                          'https://api.telegram.org/bot6458566181:AAFhpkpNKoV1RkBbAr_PipSAgUIMsYUieOg/sendMessage',
                          options
                        )
                          .then(response => response.json())
                          .then(response => console.log(response))
                          .catch(err => console.error(err))

                        setEmail('')
                        setHasEmailError(true)
                        setPhone('')
                      }
                    }}
                    variation='primary'
                    children='Me interesa'
                    {...getOverrideProps(overrides, 'Button29766686')}
                  >
                    <Button
                      variant='primary'
                      disabled={hasEmailError || !phone.length}
                      onClick={handleClose2}
                    >
                      Continuar
                    </Button>
                  </a>
                </Modal.Footer>
              </Modal>
            </>
            {/* </a> */}
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}
