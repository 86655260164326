import React from "react";
import './App.css';
import { ThemeProvider } from "@aws-amplify/ui-react";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams
} from "react-router-dom";

import {
  NavBarHeader,
  HeroLayout2,
  ProductsCountryCollection,
  MarketingPricing,
  MarketingFooter,
  PackagesCollection,
  studioTheme
} from './components';

export default function App() {

  return (<ThemeProvider theme={studioTheme}>
    <NavBarHeader width={'unset'} overrides={{ SelectField: { options: ["Argentina 🇦🇷", "Bolivia 🇧🇴", "Brasil 🇧🇷", "Chile 🇨🇱", "Colombia 🇨🇴", "Ecuador 🇪🇨", "El Salvador 🇸🇻", "Guatemala 🇭🇳", "Honduras 🇭🇳", "Mexico 🇲🇽", "Nicaragua 🇳🇮", "Panama 🇵🇦", "Paraguay 🇵🇾", "Peru 🇵🇾", "Republica Dominicana 🇩🇴", "Uruguay 🇺🇾", "Estados Unidos 🇺🇸", "Rusia 🇷🇺"] } }} />
    <HeroLayout2 colorMode='dark' width={'unset'} overrides={{ "Button": { "children": "test" }, "Title": { children: "NUTRICION Y CUIDADO PERSONAL" }, MainText: { children: "ASESORA EN SUPLEMENTOS ALIMENTICIOS OMNILIFE Y BELLEZA SEYTÚ" }, Body: { children: "ESMERALDA ALCOCER, EMPRESARIA OMNILIFE" } }} />
    <MarketingPricing width={'unset'} />
    <PackagesCollection />
    <ProductsCountryCollection />
    <MarketingFooter />
  </ThemeProvider>);
  // return (
  //   <Router>
  //     <div>
  //       <nav>
  //         <ul>
  //           <li>
  //             <Link to="/">Home</Link>
  //           </li>
  //           <li>
  //             <Link to="/product/:id">Product</Link>
  //           </li>
  //         </ul>
  //       </nav>

  //       {/* A <Switch> looks through its children <Route>s and
  //           renders the first one that matches the current URL. */}
  //       <Switch>
  //         <Route path="/product/:id">
  //           <Product />
  //         </Route>
  //         <Route path="/">
  //           <Home />
  //         </Route>
  //       </Switch>
  //     </div>
  //   </Router>
  // );
}

// function Home() {
//   return (<ThemeProvider theme={studioTheme}>
//     <NavBarHeader width={'unset'} overrides={{ SelectField: { options: ["Argentina 🇦🇷", "Bolivia 🇧🇴", "Brasil 🇧🇷", "Chile 🇨🇱", "Colombia 🇨🇴", "Ecuador 🇪🇨", "El Salvador 🇸🇻", "Guatemala 🇭🇳", "Honduras 🇭🇳", "Mexico 🇲🇽", "Nicaragua 🇳🇮", "Panama 🇵🇦", "Paraguay 🇵🇾", "Peru 🇵🇾", "Republica Dominicana 🇩🇴", "Uruguay 🇺🇾", "Estados Unidos 🇺🇸", "Rusia 🇷🇺"] } }} />
//     <HeroLayout2 colorMode='dark' width={'unset'} overrides={{ "Button": { "children": "test" }, "Title": { children: "NUTRICION Y CUIDADO PERSONAL" }, MainText: { children: "ASESORA EN SUPLEMENTOS ALIMENTICIOS OMNILIFE Y BELLEZA SEYTÚ" }, Body: { children: "ESMERALDA ALCOCER, EMPRESARIA OMNILIFE" } }} />
//     <MarketingPricing width={'unset'} />
//     <PackagesCollection />
//     <ProductsCountryCollection />
//     <MarketingFooter />
//   </ThemeProvider>);
// }

// function Product() {
//   let { id } = useParams();
//   return <h2>Product {id}</h2>;
// }


