/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
} from "@aws-amplify/ui-react/internal";
import { Flex, Text, useBreakpointValue } from "@aws-amplify/ui-react";

export default function HeroLayout2(props) {
  const { overrides: overridesProp, ...restProp } = props;
  const variants = [
    {
      overrides: {
        Title: {},
        MainText: {},
        Body: {},
        MainBody: {},
        Button: {},
        TypeLockUp: {},
        HeroLayout2: {},
      },
      variantValues: { colorMode: "Default", breakpoint: "medium" },
    },
    {
      overrides: {
        Title: {},
        MainText: {},
        Body: {},
        MainBody: {},
        Button: {},
        TypeLockUp: {},
        HeroLayout2: { width: "480px", padding: "16px 24px 16px 24px" },
      },
      variantValues: { colorMode: "Default", breakpoint: "base" },
    },
    {
      overrides: {
        Title: { color: "rgba(188,236,245,1)" },
        MainText: { color: "rgba(254,230,254,1)" },
        Body: { color: "rgba(249,184,249,1)" },
        MainBody: {},
        Button: {},
        TypeLockUp: {},
        HeroLayout2: {
          padding: "160px 240px 160px 240px",
          backgroundColor: "rgba(77,0,77,1)",
        },
      },
      variantValues: { colorMode: "dark", breakpoint: "medium" },
    },
    {
      overrides: {
        Title: { color: "rgba(188,236,245,1)" },
        MainText: { color: "rgba(254,230,254,1)" },
        Body: { color: "rgba(249,184,249,1)" },
        MainBody: {},
        Button: {},
        TypeLockUp: {},
        HeroLayout2: {
          width: "480px",
          padding: "160px 24px 160px 24px",
          backgroundColor: "rgba(77,0,77,1)",
        },
      },
      variantValues: { colorMode: "dark", breakpoint: "base" },
    },
  ];
  const breakpointHook = useBreakpointValue({
    base: "base",
    base: "base",
    medium: "medium",
    medium: "medium",
  });
  const rest = { style: { transition: "all 0.25s" }, ...restProp };
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, {
      breakpoint: breakpointHook,
      ...props,
    }),
    overridesProp || {}
  );
  return (
    <Flex
      gap="10px"
      direction="column"
      width="994px"
      height="548px"
      justifyContent="center"
      alignItems="center"
      overflow="hidden"
      position="relative"
      padding="16px 260px 16px 260px"
      backgroundColor="rgba(254,230,254,1)"
      display="flex"
      {...getOverrideProps(overrides, "HeroLayout2")}
      {...rest}
    >
      <Flex
        gap="24px"
        direction="column"
        width="unset"
        height="unset"
        justifyContent="center"
        alignItems="center"
        grow="1"
        shrink="1"
        basis="0"
        alignSelf="stretch"
        position="relative"
        padding="0px 0px 0px 0px"
        display="flex"
        {...getOverrideProps(overrides, "TypeLockUp")}
      >
        <Text
          fontFamily="Inter"
          fontSize="16px"
          fontWeight="700"
          color="rgba(149,4,149,1)"
          lineHeight="24px"
          textAlign="center"
          display="block"
          direction="column"
          justifyContent="unset"
          width="unset"
          height="unset"
          gap="unset"
          alignItems="unset"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="Full stack"
          {...getOverrideProps(overrides, "Title")}
        ></Text>
        <Flex
          gap="16px"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="flex-start"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          display="flex"
          {...getOverrideProps(overrides, "MainBody")}
        >
          <Text
            fontFamily="Inter"
            fontSize="24px"
            fontWeight="600"
            color="rgba(13,26,38,1)"
            lineHeight="30px"
            textAlign="center"
            display="block"
            direction="column"
            justifyContent="unset"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="Build full-stack web and mobile apps in hours. Easy to start, easy to scale"
            {...getOverrideProps(overrides, "MainText")}
          ></Text>
          <Text
            fontFamily="Inter"
            fontSize="16px"
            fontWeight="400"
            color="rgba(48,64,80,1)"
            lineHeight="24px"
            textAlign="center"
            display="block"
            direction="column"
            justifyContent="unset"
            letterSpacing="0.01px"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="AWS Amplify is a complete solution that lets frontend web and mobile developers easily build, ship, and host full-stack applications on AWS, with the flexibility to leverage the breadth of AWS services as use cases evolve. No cloud expertise needed."
            {...getOverrideProps(overrides, "Body")}
          ></Text>
        </Flex>
      </Flex>
    </Flex>
  );
}
