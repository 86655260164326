/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from 'react'
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
  useNavigateAction
} from './utils'
import {
  Badge,
  Button,
  Divider,
  Flex,
  Image,
  StepperField,
  Text,
  useBreakpointValue,
  useTheme
} from '@aws-amplify/ui-react'
import MyIcon from './MyIcon'
import 'react-responsive-carousel/lib/styles/carousel.min.css' // requires a loader
import { Carousel } from 'react-responsive-carousel'

export default function EsmeGram(props) {
  const { tokens } = useTheme()
  const {
    category,
    packages,
    brand,
    price,
    overrides: overridesProp,
    ...restProp
  } = props

  const variants = [
    {
      overrides: {
        image: {},
        MyIcon29766772: {},
        MyIcon29766773: {},
        MyIcon29766774: {},
        Share: {},
        StepperField: {},
        Book: {},
        Button: {},
        Options: {},
        Divider: {},
        name: {},
        Badge: {},
        'Frame 441': {},
        'Contiene ciertos ingredientes naturales, la f\u00F3rmula de la espuma limpiadora facial SEYT\u00DA es ideal para una limpieza profunda.Contiene ciertos ingredientes naturales, la f\u00F3rmula de la espuma limpiadora facial SEYT\u00DA es ideal para una limpieza profunda.':
          {},
        Area: {},
        MyIcon39021733: {},
        'Drake Bay': {},
        Departure: {},
        MyIcon39021746: {},
        '3 hours': {},
        Duration: {},
        'Frame 440': {},
        Body: {},
        'Frame 454': {},
        EsmeGram: {}
      },
      variantValues: { breakpoint: 'medium' }
    }
  ]
  const breakpointHook = useBreakpointValue({
    base: 'medium',
    medium: 'medium'
  })
  const rest = { style: { transition: 'all 0.25s' }, ...restProp }
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, {
      breakpoint: breakpointHook,
      ...props
    }),
    overridesProp || {}
  )
  const imageOnClick = useNavigateAction({
    type: 'url',
    url: packages?.description
  })
  const redondearACincoCientos = numeros => {
    // Obtenemos el resto de la división del número entre 500
    let price = 0
    for (let i = 0; i < numeros.length; i++) {
      if (numeros[i]) {
        numeros[i].price = numeros[i].price?.replace('₡', '')
        price += +numeros[i].price
      }
    }

    return '₡' + price

    price = parseInt(price)
    const resto = price % 500

    // Si el resto es mayor o igual a 250, redondeamos al siguiente múltiplo de 500
    if (resto >= 250) {
      return '₡' + (price + (500 - resto))
    }

    // De lo contrario, redondeamos al siguiente múltiplo de 500
    return '₡' + Math.ceil(price / 500) * 500
  }

  const items = packages.products

  return (
    <Flex
      gap='8px'
      direction='column'
      width='unset'
      height='unset'
      justifyContent='flex-start'
      alignItems='flex-start'
      position='relative'
      padding='0px 0px 0px 0px'
      borderRadius='8px'
      backgroundColor='rgba(255,255,255,1)'
      display='flex'
      {...getOverrideProps(overrides, 'EsmeGram')}
      {...rest}
    >
      <Flex
        gap='8px'
        direction='column'
        width='320px'
        // height='795px'
        justifyContent='flex-start'
        alignItems='flex-start'
        overflow='hidden'
        shrink='0'
        position='relative'
        padding='0px 0px 0px 0px'
        borderRadius='8px'
        backgroundColor='rgba(255,255,255,1)'
        display='flex'
        {...getOverrideProps(overrides, 'Frame 454')}
      >
        <Carousel
          showThumbs={false}
          statusFormatter={(currentItem, total) => `${currentItem} de ${total}`}
          interval={3000}
          autoPlay={false}
          infiniteLoop={false}
          renderArrowNext={() => true}
        >
          {items.map(product => {
            return (
              <div>
                <Image
                  width='unset'
                  max-height='400px'
                  display='block'
                  gap='unset'
                  alignItems='unset'
                  justifyContent='unset'
                  grow='1'
                  shrink='1'
                  basis='0'
                  alignSelf='stretch'
                  position='relative'
                  border=''
                  padding='0px 0px 0px 0px'
                  objectFit='contain'
                  alt={product?.name}
                  src={product?.image}
                  onClick={() => {
                    //imageOnClick()
                  }}
                  {...getOverrideProps(overrides, 'image')}
                ></Image>
                <p style={{ fontSize: '10px' }}>
                  <strong>{product?.name}</strong>
                </p>
                <br></br>
              </div>
            )
          })}
        </Carousel>
        <Flex
          gap='16px'
          direction='row'
          width='unset'
          height='unset'
          justifyContent='center'
          alignItems='center'
          shrink='0'
          alignSelf='stretch'
          position='relative'
          padding='16px 16px 16px 16px'
          display='flex'
          {...getOverrideProps(overrides, 'Options')}
        >
          <Flex
            gap='24px'
            direction='row'
            width='unset'
            height='unset'
            justifyContent='flex-start'
            alignItems='flex-start'
            shrink='0'
            position='relative'
            padding='0px 0px 0px 0px'
            display='none'
            {...getOverrideProps(overrides, 'Share')}
          >
            <MyIcon
              width='24px'
              height='24px'
              display='none'
              gap='unset'
              alignItems='unset'
              justifyContent='unset'
              overflow='hidden'
              shrink='0'
              position='relative'
              padding='0px 0px 0px 0px'
              type='favorite_border'
              {...getOverrideProps(overrides, 'MyIcon29766772')}
            ></MyIcon>
            <MyIcon
              width='24px'
              height='24px'
              display='none'
              gap='unset'
              alignItems='unset'
              justifyContent='unset'
              overflow='hidden'
              shrink='0'
              position='relative'
              padding='0px 0px 0px 0px'
              type='chat-bubble-outline'
              {...getOverrideProps(overrides, 'MyIcon29766773')}
            ></MyIcon>
            <MyIcon
              width='24px'
              height='24px'
              display='none'
              gap='unset'
              alignItems='unset'
              justifyContent='unset'
              overflow='hidden'
              shrink='0'
              position='relative'
              padding='0px 0px 0px 0px'
              type='send'
              {...getOverrideProps(overrides, 'MyIcon29766774')}
            ></MyIcon>
          </Flex>
          <Flex
            gap='10px'
            direction='row'
            width='unset'
            height='33px'
            justifyContent='flex-end'
            alignItems='center'
            grow='1'
            shrink='1'
            basis='0'
            position='relative'
            padding='0px 0px 0px 0px'
            display='flex'
            {...getOverrideProps(overrides, 'Book')}
          >
            <StepperField
              width='unset'
              height='unset'
              display='none'
              shrink='0'
              size='small'
              isDisabled={false}
              labelHidden={true}
              variation='default'
              {...getOverrideProps(overrides, 'StepperField')}
            ></StepperField>
          </Flex>
          <a
            href={`https://wa.me/50688159414?text=Hola, me gustaría adquirir este producto: ${packages?.name}`}
            target='_blank'
            rel='noopener noreferrer'
            width='unset'
            height='unset'
            shrink='0'
            alignSelf='stretch'
            size='large'
            isDisabled={false}
            variation='primary'
            children='Me interesa'
            {...getOverrideProps(overrides, 'Button29766686')}
          >
            <Button
              width='unset'
              height='unset'
              shrink='0'
              alignSelf='stretch'
              size='large'
              isDisabled={false}
              variation='primary'
              children='Lo quiero'
              {...getOverrideProps(overrides, 'Button29766686')}
            ></Button>
          </a>
        </Flex>
        <Divider
          width='640px'
          height='1px'
          shrink='0'
          size='small'
          orientation='horizontal'
          {...getOverrideProps(overrides, 'Divider')}
        ></Divider>
        <Flex
          gap='0'
          direction='column'
          width='unset'
          height='unset'
          justifyContent='flex-start'
          alignItems='flex-start'
          shrink='0'
          alignSelf='stretch'
          position='relative'
          padding='0px 0px 0px 0px'
          display='flex'
          {...getOverrideProps(overrides, 'Body')}
        >
          <Flex
            gap='8px'
            direction='column'
            width='unset'
            height='unset'
            justifyContent='flex-start'
            alignItems='flex-start'
            shrink='0'
            alignSelf='stretch'
            position='relative'
            padding='16px 16px 16px 16px'
            display='flex'
            {...getOverrideProps(overrides, 'Area')}
          >
            <Flex
              gap='0'
              direction='row'
              width='unset'
              height='fit-content'
              justifyContent='center'
              alignItems='center'
              overflow='hidden'
              shrink='0'
              alignSelf='stretch'
              position='relative'
              padding='0px 0px 0px 0px'
              display='flex'
              {...getOverrideProps(overrides, 'Frame 441')}
            >
              <Text
                fontFamily='Inter'
                fontSize='16px'
                fontWeight='700'
                color='rgba(13,26,38,1)'
                lineHeight='20px'
                textAlign='left'
                display='block'
                direction='column'
                justifyContent='unset'
                width='unset'
                height='unset'
                gap='unset'
                alignItems='unset'
                grow='1'
                shrink='1'
                basis='0'
                position='relative'
                padding='0px 0px 0px 0px'
                whiteSpace='pre-wrap'
                children={packages?.name}
                {...getOverrideProps(overrides, 'name')}
              >
                {packages?.name}
                <Text
                  color={tokens.colors.font.tertiary}
                  fontSize='12px'
                  paddingRight='2px'
                >
                  {packages.products.length} productos
                </Text>
              </Text>
              <Badge
                width='unset'
                height='unset'
                shrink='0'
                size='default'
                variation='success'
                children={`${redondearACincoCientos(packages?.products)}${''}`}
                {...getOverrideProps(overrides, 'Badge')}
              ></Badge>
            </Flex>
            {/* <Text
              fontFamily='Inter'
              fontSize='16px'
              fontWeight='400'
              color='rgba(48,64,80,1)'
              lineHeight='24px'
              textAlign='left'
              display='block'
              direction='column'
              justifyContent='unset'
              letterSpacing='0.01px'
              width='unset'
              height='unset'
              gap='unset'
              alignItems='unset'
              shrink='0'
              alignSelf='stretch'
              position='relative'
              padding='0px 0px 0px 0px'
              whiteSpace='pre-wrap'
              children={packages?.description}
              {...getOverrideProps(
                overrides,
                'Contiene ciertos ingredientes naturales, la f\u00F3rmula de la espuma limpiadora facial SEYT\u00DA es ideal para una limpieza profunda.Contiene ciertos ingredientes naturales, la f\u00F3rmula de la espuma limpiadora facial SEYT\u00DA es ideal para una limpieza profunda.'
              )}
            ></Text> */}
            <MoreLessComponent
              fullText={packages?.description}
            ></MoreLessComponent>
          </Flex>
          <Flex
            gap='8px'
            direction='row'
            width='unset'
            height='unset'
            justifyContent='flex-start'
            alignItems='center'
            overflow='hidden'
            shrink='0'
            alignSelf='stretch'
            position='relative'
            padding='0px 16px 0px 16px'
            display='none'
            {...getOverrideProps(overrides, 'Frame 440')}
          >
            <Flex
              gap='8px'
              direction='row'
              width='unset'
              height='unset'
              justifyContent='flex-start'
              alignItems='center'
              overflow='hidden'
              shrink='0'
              position='relative'
              padding='8px 0px 8px 0px'
              display='flex'
              {...getOverrideProps(overrides, 'Departure')}
            >
              <MyIcon
                width='24px'
                height='24px'
                display='block'
                gap='unset'
                alignItems='unset'
                justifyContent='unset'
                overflow='hidden'
                shrink='0'
                position='relative'
                padding='0px 0px 0px 0px'
                type='location'
                {...getOverrideProps(overrides, 'MyIcon39021733')}
              ></MyIcon>
              <Text
                fontFamily='Inter'
                fontSize='16px'
                fontWeight='700'
                color='rgba(0,0,0,1)'
                lineHeight='24px'
                textAlign='left'
                display='block'
                direction='column'
                justifyContent='unset'
                width='unset'
                height='unset'
                gap='unset'
                alignItems='unset'
                shrink='0'
                position='relative'
                padding='0px 0px 0px 0px'
                whiteSpace='pre-wrap'
                children='Drake Bay'
                {...getOverrideProps(overrides, 'Drake Bay')}
              ></Text>
            </Flex>
            <Flex
              gap='8px'
              direction='row'
              width='unset'
              height='unset'
              justifyContent='flex-end'
              alignItems='center'
              overflow='hidden'
              grow='1'
              shrink='1'
              basis='0'
              position='relative'
              padding='8px 0px 8px 0px'
              display='flex'
              {...getOverrideProps(overrides, 'Duration')}
            >
              <MyIcon
                width='24px'
                height='24px'
                display='block'
                gap='unset'
                alignItems='unset'
                justifyContent='unset'
                overflow='hidden'
                shrink='0'
                position='relative'
                padding='0px 0px 0px 0px'
                type='dashboard'
                {...getOverrideProps(overrides, 'MyIcon39021746')}
              ></MyIcon>
              <Text
                fontFamily='Inter'
                fontSize='16px'
                fontWeight='700'
                color='rgba(0,0,0,1)'
                lineHeight='24px'
                textAlign='left'
                display='block'
                direction='column'
                justifyContent='unset'
                width='unset'
                height='unset'
                gap='unset'
                alignItems='unset'
                shrink='0'
                position='relative'
                padding='0px 0px 0px 0px'
                whiteSpace='pre-wrap'
                children='3 hours'
                {...getOverrideProps(overrides, '3 hours')}
              ></Text>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}

const MoreLessComponent = ({ fullText }) => {
  const [more, setMore] = React.useState(true)
  const { tokens } = useTheme()
  const length = 200
  return (
    <div>
      <Text>
        {more && fullText.length > length
          ? `${fullText.slice(0, length)}...`
          : fullText}
        {fullText.length > length && (
          <Text
            isDisabled={fullText.length > length}
            color={tokens.colors.font.info}
            onClick={() => setMore(!more)}
          >
            {more ? 'Ver más' : 'Ver menos'}
          </Text>
        )}
      </Text>
    </div>
  )
}
