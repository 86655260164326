/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from 'react'
import { getOverrideProps } from '@aws-amplify/ui-react/internal'
import { Flex, SelectField, Button, Text, Link } from '@aws-amplify/ui-react'
import {
  FaFacebook,
  FaTiktok,
  FaInstagram,
  FaWhatsapp,
  FaLink
} from 'react-icons/fa6'

import MyIcon from './MyIcon'
export default function NavBarHeader(props) {
  const { overrides, ...rest } = props
  const [items, setItems] = React.useState([])

  const countries = [
    {
      id: 'CR',
      name: 'Costa Rica',
      currency: '₡',
      flag: '🇨🇷',
      language: 'ES',
      hasSeytu: true
    },
    {
      id: 'AR',
      name: 'Argentina',
      currency: '$',
      flag: '🇦🇷',
      language: 'ES',
      hasSeytu: true
    },
    {
      id: 'BO',
      name: 'Bolivia',
      currency: 'Bs',
      flag: '🇧🇴',
      language: 'ES',
      hasSeytu: true
    },
    {
      id: 'CH',
      name: 'Chile',
      currency: '$',
      flag: '🇨🇱',
      language: 'ES',
      hasSeytu: true
    },
    {
      id: 'CO',
      name: 'Colombia',
      currency: '$',
      flag: '🇨🇴',
      language: 'ES',
      hasSeytu: true
    }
  ]

  return (
    <Flex
      gap='10px'
      direction='row'
      width='631.55px'
      height='unset'
      // justifyContent='center'
      // alignItems='center'
      position='relative'
      boxShadow='0px 2px 6px rgba(0.05098039284348488, 0.10196078568696976, 0.14901961386203766, 0.15000000596046448)'
      padding='24px 32px 24px 32px'
      backgroundColor='rgba(255,255,255,1)'
      {...getOverrideProps(overrides, 'NavBarHeader')}
      {...rest}
    >
      <Flex width='50%' alignItems='left'>
        <Link href='https://www.facebook.com/Magicnatural.CR' isExternal={true}>
          <FaFacebook size='30px'></FaFacebook>
        </Link>

        <Link
          href='https://instagram.com/_nutribellezamagic?igshid=MDE2OWE1N2Q='
          isExternal={true}
        >
          <FaInstagram size='30px'></FaInstagram>
        </Link>

        <Link
          href='https://www.tiktok.com/@nutricionybellezaconesme?_t=8hTux7dBvXh&_r=1'
          isExternal={true}
        >
          <FaTiktok size='30px'></FaTiktok>
        </Link>

        <Link href='https://wa.me/50688159414' isExternal={true}>
          <FaWhatsapp size='30px'></FaWhatsapp>
        </Link>
      </Flex>
      <Flex
        justifyContent='end'
        alignContent='end'
        alignItems='right'
        width='50%'
      >
        <Link
          href='https://l.wl.co/l?u=https%3A%2F%2Fempresarioseytu.omnilife.com%2Fcr%2Fsan-jose%2Fsan-jose%2F5068815941%2Fesmeralda-alcocer-obando'
          isExternal={true}
        >
          <FaLink size='30px'></FaLink>
        </Link>
      </Flex>
    </Flex>
  )
}
