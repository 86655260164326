/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getPackages = /* GraphQL */ `
  query GetPackages($id: ID!) {
    getPackages(id: $id) {
      id
      name
      description
      short_description
      Products {
        items {
          id
          packagesId
          productsId
          packages {
            id
            name
            description
            short_description
            createdAt
            updatedAt
            __typename
          }
          products {
            id
            name
            status
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listPackages = /* GraphQL */ `
  query ListPackages(
    $filter: ModelPackagesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPackages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        short_description
        Products {
          items {
            id
            packagesId
            productsId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getEmailsSubscriptions = /* GraphQL */ `
  query GetEmailsSubscriptions($id: ID!) {
    getEmailsSubscriptions(id: $id) {
      id
      email
      name
      phone
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listEmailsSubscriptions = /* GraphQL */ `
  query ListEmailsSubscriptions(
    $filter: ModelEmailsSubscriptionsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEmailsSubscriptions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        email
        name
        phone
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getProductCountry = /* GraphQL */ `
  query GetProductCountry($id: ID!) {
    getProductCountry(id: $id) {
      id
      Products {
        id
        name
        status
        packagess {
          items {
            id
            packagesId
            productsId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      Country {
        id
        name
        currency
        flag
        hasSeytu
        language
        createdAt
        updatedAt
        __typename
      }
      name
      description
      short_description
      image
      price
      points
      Category {
        id
        title
        description
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      productCountryProductsId
      productCountryCountryId
      productCountryCategoryId
      __typename
    }
  }
`;
export const listProductCountries = /* GraphQL */ `
  query ListProductCountries(
    $filter: ModelProductCountryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProductCountries(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        Products {
          id
          name
          status
          packagess {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        Country {
          id
          name
          currency
          flag
          hasSeytu
          language
          createdAt
          updatedAt
          __typename
        }
        name
        description
        short_description
        image
        price
        points
        Category {
          id
          title
          description
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        productCountryProductsId
        productCountryCountryId
        productCountryCategoryId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getProducts = /* GraphQL */ `
  query GetProducts($id: ID!) {
    getProducts(id: $id) {
      id
      name
      status
      packagess {
        items {
          id
          packagesId
          productsId
          packages {
            id
            name
            description
            short_description
            createdAt
            updatedAt
            __typename
          }
          products {
            id
            name
            status
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listProducts = /* GraphQL */ `
  query ListProducts(
    $filter: ModelProductsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProducts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        status
        packagess {
          items {
            id
            packagesId
            productsId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCountry = /* GraphQL */ `
  query GetCountry($id: ID!) {
    getCountry(id: $id) {
      id
      name
      currency
      flag
      hasSeytu
      language
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listCountries = /* GraphQL */ `
  query ListCountries(
    $filter: ModelCountryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCountries(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        currency
        flag
        hasSeytu
        language
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getBrand = /* GraphQL */ `
  query GetBrand($id: ID!) {
    getBrand(id: $id) {
      id
      name
      image
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listBrands = /* GraphQL */ `
  query ListBrands(
    $filter: ModelBrandFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBrands(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        image
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCategory = /* GraphQL */ `
  query GetCategory($id: ID!) {
    getCategory(id: $id) {
      id
      title
      description
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listCategories = /* GraphQL */ `
  query ListCategories(
    $filter: ModelCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCategories(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        description
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPackagesProducts = /* GraphQL */ `
  query GetPackagesProducts($id: ID!) {
    getPackagesProducts(id: $id) {
      id
      packagesId
      productsId
      packages {
        id
        name
        description
        short_description
        Products {
          items {
            id
            packagesId
            productsId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      products {
        id
        name
        status
        packagess {
          items {
            id
            packagesId
            productsId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listPackagesProducts = /* GraphQL */ `
  query ListPackagesProducts(
    $filter: ModelPackagesProductsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPackagesProducts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        packagesId
        productsId
        packages {
          id
          name
          description
          short_description
          Products {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        products {
          id
          name
          status
          packagess {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const packagesProductsByPackagesId = /* GraphQL */ `
  query PackagesProductsByPackagesId(
    $packagesId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelPackagesProductsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    packagesProductsByPackagesId(
      packagesId: $packagesId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        packagesId
        productsId
        packages {
          id
          name
          description
          short_description
          Products {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        products {
          id
          name
          status
          packagess {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const packagesProductsByProductsId = /* GraphQL */ `
  query PackagesProductsByProductsId(
    $productsId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelPackagesProductsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    packagesProductsByProductsId(
      productsId: $productsId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        packagesId
        productsId
        packages {
          id
          name
          description
          short_description
          Products {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        products {
          id
          name
          status
          packagess {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
