/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createPackages = /* GraphQL */ `
  mutation CreatePackages(
    $input: CreatePackagesInput!
    $condition: ModelPackagesConditionInput
  ) {
    createPackages(input: $input, condition: $condition) {
      id
      name
      description
      short_description
      Products {
        items {
          id
          packagesId
          productsId
          packages {
            id
            name
            description
            short_description
            createdAt
            updatedAt
            __typename
          }
          products {
            id
            name
            status
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updatePackages = /* GraphQL */ `
  mutation UpdatePackages(
    $input: UpdatePackagesInput!
    $condition: ModelPackagesConditionInput
  ) {
    updatePackages(input: $input, condition: $condition) {
      id
      name
      description
      short_description
      Products {
        items {
          id
          packagesId
          productsId
          packages {
            id
            name
            description
            short_description
            createdAt
            updatedAt
            __typename
          }
          products {
            id
            name
            status
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deletePackages = /* GraphQL */ `
  mutation DeletePackages(
    $input: DeletePackagesInput!
    $condition: ModelPackagesConditionInput
  ) {
    deletePackages(input: $input, condition: $condition) {
      id
      name
      description
      short_description
      Products {
        items {
          id
          packagesId
          productsId
          packages {
            id
            name
            description
            short_description
            createdAt
            updatedAt
            __typename
          }
          products {
            id
            name
            status
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createEmailsSubscriptions = /* GraphQL */ `
  mutation CreateEmailsSubscriptions(
    $input: CreateEmailsSubscriptionsInput!
    $condition: ModelEmailsSubscriptionsConditionInput
  ) {
    createEmailsSubscriptions(input: $input, condition: $condition) {
      id
      email
      name
      phone
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateEmailsSubscriptions = /* GraphQL */ `
  mutation UpdateEmailsSubscriptions(
    $input: UpdateEmailsSubscriptionsInput!
    $condition: ModelEmailsSubscriptionsConditionInput
  ) {
    updateEmailsSubscriptions(input: $input, condition: $condition) {
      id
      email
      name
      phone
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteEmailsSubscriptions = /* GraphQL */ `
  mutation DeleteEmailsSubscriptions(
    $input: DeleteEmailsSubscriptionsInput!
    $condition: ModelEmailsSubscriptionsConditionInput
  ) {
    deleteEmailsSubscriptions(input: $input, condition: $condition) {
      id
      email
      name
      phone
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createProductCountry = /* GraphQL */ `
  mutation CreateProductCountry(
    $input: CreateProductCountryInput!
    $condition: ModelProductCountryConditionInput
  ) {
    createProductCountry(input: $input, condition: $condition) {
      id
      Products {
        id
        name
        status
        packagess {
          items {
            id
            packagesId
            productsId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      Country {
        id
        name
        currency
        flag
        hasSeytu
        language
        createdAt
        updatedAt
        __typename
      }
      name
      description
      short_description
      image
      price
      points
      Category {
        id
        title
        description
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      productCountryProductsId
      productCountryCountryId
      productCountryCategoryId
      __typename
    }
  }
`;
export const updateProductCountry = /* GraphQL */ `
  mutation UpdateProductCountry(
    $input: UpdateProductCountryInput!
    $condition: ModelProductCountryConditionInput
  ) {
    updateProductCountry(input: $input, condition: $condition) {
      id
      Products {
        id
        name
        status
        packagess {
          items {
            id
            packagesId
            productsId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      Country {
        id
        name
        currency
        flag
        hasSeytu
        language
        createdAt
        updatedAt
        __typename
      }
      name
      description
      short_description
      image
      price
      points
      Category {
        id
        title
        description
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      productCountryProductsId
      productCountryCountryId
      productCountryCategoryId
      __typename
    }
  }
`;
export const deleteProductCountry = /* GraphQL */ `
  mutation DeleteProductCountry(
    $input: DeleteProductCountryInput!
    $condition: ModelProductCountryConditionInput
  ) {
    deleteProductCountry(input: $input, condition: $condition) {
      id
      Products {
        id
        name
        status
        packagess {
          items {
            id
            packagesId
            productsId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      Country {
        id
        name
        currency
        flag
        hasSeytu
        language
        createdAt
        updatedAt
        __typename
      }
      name
      description
      short_description
      image
      price
      points
      Category {
        id
        title
        description
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      productCountryProductsId
      productCountryCountryId
      productCountryCategoryId
      __typename
    }
  }
`;
export const createProducts = /* GraphQL */ `
  mutation CreateProducts(
    $input: CreateProductsInput!
    $condition: ModelProductsConditionInput
  ) {
    createProducts(input: $input, condition: $condition) {
      id
      name
      status
      packagess {
        items {
          id
          packagesId
          productsId
          packages {
            id
            name
            description
            short_description
            createdAt
            updatedAt
            __typename
          }
          products {
            id
            name
            status
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateProducts = /* GraphQL */ `
  mutation UpdateProducts(
    $input: UpdateProductsInput!
    $condition: ModelProductsConditionInput
  ) {
    updateProducts(input: $input, condition: $condition) {
      id
      name
      status
      packagess {
        items {
          id
          packagesId
          productsId
          packages {
            id
            name
            description
            short_description
            createdAt
            updatedAt
            __typename
          }
          products {
            id
            name
            status
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteProducts = /* GraphQL */ `
  mutation DeleteProducts(
    $input: DeleteProductsInput!
    $condition: ModelProductsConditionInput
  ) {
    deleteProducts(input: $input, condition: $condition) {
      id
      name
      status
      packagess {
        items {
          id
          packagesId
          productsId
          packages {
            id
            name
            description
            short_description
            createdAt
            updatedAt
            __typename
          }
          products {
            id
            name
            status
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createCountry = /* GraphQL */ `
  mutation CreateCountry(
    $input: CreateCountryInput!
    $condition: ModelCountryConditionInput
  ) {
    createCountry(input: $input, condition: $condition) {
      id
      name
      currency
      flag
      hasSeytu
      language
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateCountry = /* GraphQL */ `
  mutation UpdateCountry(
    $input: UpdateCountryInput!
    $condition: ModelCountryConditionInput
  ) {
    updateCountry(input: $input, condition: $condition) {
      id
      name
      currency
      flag
      hasSeytu
      language
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteCountry = /* GraphQL */ `
  mutation DeleteCountry(
    $input: DeleteCountryInput!
    $condition: ModelCountryConditionInput
  ) {
    deleteCountry(input: $input, condition: $condition) {
      id
      name
      currency
      flag
      hasSeytu
      language
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createBrand = /* GraphQL */ `
  mutation CreateBrand(
    $input: CreateBrandInput!
    $condition: ModelBrandConditionInput
  ) {
    createBrand(input: $input, condition: $condition) {
      id
      name
      image
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateBrand = /* GraphQL */ `
  mutation UpdateBrand(
    $input: UpdateBrandInput!
    $condition: ModelBrandConditionInput
  ) {
    updateBrand(input: $input, condition: $condition) {
      id
      name
      image
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteBrand = /* GraphQL */ `
  mutation DeleteBrand(
    $input: DeleteBrandInput!
    $condition: ModelBrandConditionInput
  ) {
    deleteBrand(input: $input, condition: $condition) {
      id
      name
      image
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createCategory = /* GraphQL */ `
  mutation CreateCategory(
    $input: CreateCategoryInput!
    $condition: ModelCategoryConditionInput
  ) {
    createCategory(input: $input, condition: $condition) {
      id
      title
      description
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateCategory = /* GraphQL */ `
  mutation UpdateCategory(
    $input: UpdateCategoryInput!
    $condition: ModelCategoryConditionInput
  ) {
    updateCategory(input: $input, condition: $condition) {
      id
      title
      description
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteCategory = /* GraphQL */ `
  mutation DeleteCategory(
    $input: DeleteCategoryInput!
    $condition: ModelCategoryConditionInput
  ) {
    deleteCategory(input: $input, condition: $condition) {
      id
      title
      description
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createPackagesProducts = /* GraphQL */ `
  mutation CreatePackagesProducts(
    $input: CreatePackagesProductsInput!
    $condition: ModelPackagesProductsConditionInput
  ) {
    createPackagesProducts(input: $input, condition: $condition) {
      id
      packagesId
      productsId
      packages {
        id
        name
        description
        short_description
        Products {
          items {
            id
            packagesId
            productsId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      products {
        id
        name
        status
        packagess {
          items {
            id
            packagesId
            productsId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updatePackagesProducts = /* GraphQL */ `
  mutation UpdatePackagesProducts(
    $input: UpdatePackagesProductsInput!
    $condition: ModelPackagesProductsConditionInput
  ) {
    updatePackagesProducts(input: $input, condition: $condition) {
      id
      packagesId
      productsId
      packages {
        id
        name
        description
        short_description
        Products {
          items {
            id
            packagesId
            productsId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      products {
        id
        name
        status
        packagess {
          items {
            id
            packagesId
            productsId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deletePackagesProducts = /* GraphQL */ `
  mutation DeletePackagesProducts(
    $input: DeletePackagesProductsInput!
    $condition: ModelPackagesProductsConditionInput
  ) {
    deletePackagesProducts(input: $input, condition: $condition) {
      id
      packagesId
      productsId
      packages {
        id
        name
        description
        short_description
        Products {
          items {
            id
            packagesId
            productsId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      products {
        id
        name
        status
        packagess {
          items {
            id
            packagesId
            productsId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
