/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from 'react'
import {
  getProductCountry,
  getProducts,
  listPackages,
  listPackagesProducts,
  listProductCountries
} from '../graphql/queries'
import EsmeGram from './EsmeGram'
import { getOverrideProps } from '@aws-amplify/ui-react/internal'
import {
  Collection,
  Pagination,
  Text,
  Loader,
  useTheme,
  ScrollView
} from '@aws-amplify/ui-react'
import { API } from 'aws-amplify'
import { EsmeGramPackage } from '.'
const nextToken = {}
const apiCache = {}
export default function ProductsCountryCollection(props) {
  const { tokens } = useTheme()
  const { items: itemsProp, overrideItems, overrides, ...rest } = props
  const [pageIndex, setPageIndex] = React.useState(1)
  const [hasMorePages, setHasMorePages] = React.useState(true)
  const [items, setItems] = React.useState([])
  const [isApiPagination, setIsApiPagination] = React.useState(false)
  const [instanceKey, setInstanceKey] = React.useState('newGuid')
  const [loading, setLoading] = React.useState(true)
  const [maxViewed, setMaxViewed] = React.useState(1)
  const [selectedCategory, setCategory] = React.useState('')

  const pageSize = 100
  const isPaginated = false
  React.useEffect(() => {
    nextToken[instanceKey] = ''
    apiCache[instanceKey] = []
  }, [instanceKey])
  React.useEffect(() => {
    setIsApiPagination(!!!itemsProp)
  }, [itemsProp])
  const handlePreviousPage = () => {
    setPageIndex(pageIndex - 1)
  }
  const handleNextPage = () => {
    setPageIndex(pageIndex + 1)
  }
  const jumpToPage = pageNum => {
    setPageIndex(pageNum)
  }
  const loadPage = async (page, category) => {
    const cacheUntil = page * pageSize + 1
    const newCache = apiCache[instanceKey].slice()
    let newNext = nextToken[instanceKey]
    while ((newCache.length < cacheUntil || !isPaginated) && newNext != null) {
      setLoading(true)

      let variables = {}

      if (newNext) {
        variables['nextToken'] = newNext
      }

      let packages = (
        await API.graphql({
          query: listPackages
        })
      ).data.listPackages

      for (let i = 0; i < packages.items.length; i++) {
        variables = {
          filter: {
            productCountryCategoryId: {
              eq: packages.items[i].id
            }
          }
        }
        // let packageProducts = (
        //   await API.graphql({
        //     query: listPackagesProducts,
        //     variables
        //   })
        // ).data.listPackagesProducts

        packages.items[i].products = []

        for (let j = 0; j < packages.items[i].Products.items.length; j++) {
          let product = (
            await API.graphql({
              query: listProductCountries,
              variables: {
                filter: {
                  productCountryProductsId: {
                    eq: packages.items[i].Products.items[j].productsId
                  }
                }
              }
            })
          ).data.listProductCountries

          packages.items[i].products.push(product.items[0])
        }
      }

      newCache.push(...packages.items)
      newNext = packages.nextToken
    }
    const cacheSlice = isPaginated
      ? newCache.slice((page - 1) * pageSize, page * pageSize)
      : newCache
    setItems(cacheSlice)
    setHasMorePages(!!newNext)
    setLoading(false)
    apiCache[instanceKey] = newCache
    nextToken[instanceKey] = newNext
  }
  React.useEffect(() => {
    loadPage(pageIndex)
  }, [pageIndex])
  React.useEffect(() => {
    setMaxViewed(Math.max(maxViewed, pageIndex))
  }, [pageIndex, maxViewed, setMaxViewed])
  return (
    <div style={{ backgroundColor: '#EFF0F0', padding: '24px' }}>
      <Text
        fontFamily='Inter'
        fontSize='32px'
        fontWeight='600'
        color='rgba(13,26,38,1)'
        lineHeight='32px'
        textAlign='center'
        display='block'
        direction='column'
        justifyContent='unset'
        width='unset'
        height='unset'
        gap='unset'
        alignItems='unset'
        shrink='0'
        position='relative'
        padding='0px 0px 0px 0px'
        whiteSpace='pre-wrap'
        children='PAQUETES'
      ></Text>
      <br></br>
      {() => {
        if (loading) {
          return (
            <Loader margin='0 auto' size='large'>
              <br></br>
              <br></br>
              <br></br>
            </Loader>
          )
        }
      }}
      <ScrollView width='auto' className='horizontal-example'>
        <Collection
          type='grid'
          isSearchable={false}
          // searchPlaceholder='Buscar...'
          // templateColumns={{
          //   base: '1fr',
          //   small: '1fr',
          //   medium: '1fr 1fr',
          //   large: '1fr 1fr 1fr'
          // }}
          autoFlow='column'
          alignItems='stretch'
          justifyContent='stretch'
          backgroundColor={tokens.colors.background.disabled}
          // searchNoResultsFound={
          //   <Flex justifyContent='center'>
          //     <Text color='purple.80' fontSize='1rem'>
          //       Sin resultados, por favor revisa
          //     </Text>
          //   </Flex>
          // }
          // padding='12px'
          gap='10px'
          itemsPerPage={pageSize}
          isPaginated={false}
          items={itemsProp || (loading ? new Array(pageSize).fill({}) : items)}
          {...getOverrideProps(overrides, 'PackagesCollection')}
          {...rest}
        >
          {(item, index) => {
            if (loading) {
              return (
                <Loader margin='0 auto' size='large'>
                  <br></br>
                  <br></br>
                  <br></br>
                </Loader>
              )
            }
            return (
              <EsmeGramPackage
                packages={item}
                margin='0 auto'
                marginTop='12px'
                key={item.id}
                {...(overrideItems && overrideItems({ item, index }))}
              ></EsmeGramPackage>
            )
          }}
        </Collection>
      </ScrollView>

      {isApiPagination && isPaginated && (
        <Pagination
          currentPage={pageIndex}
          totalPages={maxViewed}
          hasMorePages={hasMorePages}
          onNext={handleNextPage}
          onPrevious={handlePreviousPage}
          onChange={jumpToPage}
        />
      )}
    </div>
  )
}
